<script>
/* eslint-disable no-undef */
import { requiredIf, minLength } from 'vuelidate/lib/validators'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Map, Marker, gmapApi } from 'gmap-vue'
import validationMixin from '@src/mixins/validation'
import statesWithCities from '@src/assets/resources/statesWithCities'
import translatedMultiselect from '@components/translatedMultiselect'

const ADDRESS_COMPONENTS = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    sublocality_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
}
const ADDRESS_ADICIONALS = ['formatted_address', 'place_id', 'name']
const CITIES_TYPE = ['locality', 'administrative_area_level_3']
const REGIONS_TYPE = [
    'locality',
    'sublocality',
    'postal_code',
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2',
]
const i18nCommon = 'COMMON'

export default {
    name: 'GoogleAutoComplete',

    components: {
        translatedMultiselect,
        'gmap-map': Map,
        GmapMarker: Marker,
    },

    mixins: [validationMixin],

    props: {
        id: {
            type: String,
            required: true,
            default: 'autocomplete_address',
        },

        modal: {
            type: Object,
            required: true,
        },

        types: {
            type: Array,
            default: () => {
              return ['geocode', 'establishment']
            },
        },

        country: {
            type: [String, Array],
            default: 'br',
        },

        enableGeolocation: {
            type: Boolean,
            default: false,
        },

        geolocationOptions: {
            type: Object,
            default: null,
        },

        required: {
            type: Boolean,
            required: true,
        },

        disabled: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            modalResponseErrors: {},
            pinMarker: {
                path: faMapMarkerAlt.icon[4],
                scale: 0.07,
                fillColor: '#2E7FEB',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            statesWithCities,
            gModal: {
                ...this.modal,
                humanReadableAddress: this.modal.human_readable_address,
                postalCode: this.modal.zip_code,
                longName: this.modal.long_name,
                shortName: this.modal.short_name,
                latitude: this.modal.lat,
                longitude: this.modal.long,
                placeId: this.modal.place_id,
                autocompleteText: this.modal.address,
                required: this.required,
            },
            selectedState: null,
            selectedCity: null,
            citiesByState: [],

            /**
             *
             * Necessary for the use of sessions token during the use of the api
             * @link https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
             */
            sessionToken: true,

            /**
             * Autocomplete input text
             * @type {String}
             */
        }
    },

    validations: {
        gModal: {
            district: {
                required: requiredIf(function (modal) {
                    return modal && modal.required
                }),
                minLength: minLength(3),
            },
            state: {
                id: {
                    required: requiredIf(function (modal) {
                        return modal && modal.required
                    }),
                },
            },
            city: {
                id: {
                    required: requiredIf(function (modal) {
                        return modal && modal.required
                    }),
                },
            },
            latitude: {
                required: requiredIf(function (modal) {
                    return modal && (modal.required || modal.autocompleteText)
                }),
            },
            longitude: {
                required: requiredIf(function (modal) {
                    return modal && (modal.required || modal.autocompleteText)
                }),
            },
        },
    },

    computed: {
        google: gmapApi,
        i18nAddressLabel: function () {
            return this.getI18n(i18nCommon, 'address')
        },
        i18nPostalCodeLabel: function () {
            return this.getI18n(i18nCommon, 'postal_code')
        },
        i18nLatitudeLabel: function () {
            return this.getI18n(i18nCommon, 'latitude')
        },
        i18nLongitudeLabel: function () {
            return this.getI18n(i18nCommon, 'longitude')
        },
        i18nStreetNumberLabel: function () {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'number',
                modifier: 1,
            })
        },
        i18nDistrictLabel: function () {
            return this.getI18n(i18nCommon, 'district')
        },
        i18nComplementLabel: function () {
            return this.getI18n(i18nCommon, 'complement')
        },
        i18nChainInvalidNumber() {
            return this.getI18n('ERROR_CODES.invalid_number')
        },
        i18nChainInvalidLatitude() {
            return this.getI18n('ERROR_CODES.invalid_latitude')
        },
        i18nChainInvalidLongitude() {
            return this.getI18n('ERROR_CODES.invalid_longitude')
        },
        i18nInvalidDistrictLabel() {
            return this.getI18n('ERROR_CODES.invalid_district')
        },
        i18nInvalidComplementDistrictLabel() {
            return this.getI18n('ERROR_CODES.invalid_complement')
        },
        i18nInvalidPostalCode() {
            return this.getI18n('ERROR_CODES.invalid_postalcode')
        },
        i18nInvalidAddress() {
            return this.getI18n('ERROR_CODES.invalid_address')
        },
        i18nState() {
            return this.getI18n(i18nCommon, 'state')
        },
        i18nCity() {
            return this.getI18n(i18nCommon, 'city')
        },
        i18nInvalidCityLabel() {
            return this.getI18n('ERROR_CODES.invalid_city')
        },
        i18nInvalidStateLabel() {
            return this.getI18n('ERROR_CODES.invalid_state')
        },
        displayMap() {
            return this.gModal.latitude && this.gModal.longitude
        },
        coordinatesMap() {
            if (this.displayMap) {
                return {
                    lat: this.gModal.latitude,
                    lng: this.gModal.longitude,
                }
            }

            return {
                lat: -25.4269067,
                lng: -49.2427093,
            }
        },
    },

    watch: {
        'gModal.autocompleteText': function (newVal, oldVal) {
            if (!newVal) {
                this.cleanModal()
            }

            this.$emit('inputChange', { newVal, oldVal }, this.id)
        },
        country: function (newVal, oldVal) {
            this.autocomplete.setComponentRestrictions({
                country: this.country === null ? [] : this.country,
            })
        },

        selectedState(newState) {
            this.gModal.state = newState
                ? {
                      id: newState.reference,
                      label: newState.label,
                  }
                : null
            this.selectedCity = null
            this.citiesByState =
                this.selectedState && this.selectedState.children
                    ? this.selectedState.children
                    : []
        },

        selectedCity(newCity) {
            if (newCity && newCity.id) {
                this.gModal.city_id = newCity.id
                this.gModal.city = { id: newCity.id, label: newCity.label }
            }
        },
    },

    mounted: function () {
        const options = {}

        if (this.types && this.types.length > 0) {
            options.types = this.types
        }

        if (this.country) {
            options.componentRestrictions = {
                country: this.country,
            }
        }

        this.$gmapApiPromiseLazy().then(() => {
            if(this.google) {
                if (this.sessionToken) {
                    options.sessionToken =
                        new this.google.maps.places.AutocompleteSessionToken()
                }

                this.autocomplete = new this.google.maps.places.Autocomplete(
                    document.getElementById(this.id),
                    options
                )

                this.autocomplete.addListener('place_changed', this.onPlaceChanged)
            }
        });

        const cityId = this.gModal.city ? this.gModal.city.id : null
        const stateId = this.gModal.state ? this.gModal.state.id : null
        this.selectedStateAndCityById(stateId, cityId)
    },

    methods: {
        selectedStateAndCityById(stateIndexId, cityId) {
            if (stateIndexId) {
                this.selectedState = this.statesWithCities[stateIndexId - 1]
            }
            const state = this.selectedState
            this.$nextTick(() => {
                if (state && cityId) {
                    this.selectedCity = this.selectedState.children.find(
                        (city) => city.id === cityId
                    )
                }
            })
        },

        selectStateAndCityGoogleAutoComplete(stateSlug, cityLabel) {
            if (stateSlug) {
                this.selectedState = this.statesWithCities.find(
                    (state) => state.id === stateSlug
                )
            }
            const state = this.selectedState
            this.$nextTick(() => {
                if (state && cityLabel) {
                    this.selectedCity = this.selectedState.children.find(
                        (city) => city.label.toLowerCase() === cityLabel.toLowerCase()
                    )
                }
            })
        },

        cleanModal() {
            this.gModal = Object.assign(this.gModal, {
                postalCode: null,
                zip_code: null,
                number: null,
                latitude: null,
                longitude: null,
                lat: null,
                long: null,
                address: null,
                humanReadableAddress: null,
                longName: null,
                shortName: null,
                placeId: null,
                district: null,
                state: null,
                city: null,
                complement: null,
                autocompleteText: '',
                required: this.required,
            })
            this.selectedState = null
            this.selectedCity = null
        },

        onPlaceChanged() {
            const place = this.autocomplete.getPlace()
            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                this.$emit('no-results-found', place, this.id)
                return
            }

            if (place.address_components !== undefined) {
                const results = this.formatResult(place)
                this.$emit(
                    'placechanged',
                    this.formatResult(place),
                    place,
                    this.id
                )

                this.updateStoreInfo(results)
                this.gModal.autocompleteText = document.getElementById(
                    this.id
                ).value
                this.selectStateAndCityGoogleAutoComplete(
                    results.administrative_area_level_1,
                    results.administrative_area_level_2
                )
                this.onChange()
            }
        },

        updateStoreInfo(results) {
            this.gModal.latitude = results.latitude
            this.gModal.route = results.route
            this.gModal.address = results.route
            this.gModal.longitude = results.longitude
            this.gModal.postalCode = results.postal_code ? results.postal_code.replace(/[^\d]+/g, '') : null
            this.gModal.number = results.street_number
            this.gModal.humanReadableAddress = results.formatted_address
            this.gModal.longName = results.formatted_address
            this.gModal.shortName = results.route
            this.gModal.placeId = results.place_id
            this.gModal.district = results.sublocality_level_1
        },

        /**
         * When the input gets focus
         */
        onFocus() {
            this.biasAutocompleteLocation()
            this.$emit('focus')
        },

        /**
         * When the input loses focus
         */
        onBlur() {
            this.$emit('blur')
        },

        /**
         * When the input got changed
         */
        onChange() {
            this.$emit('change', this.gModal.autocompleteText)
        },

        /**
         * When a key gets pressed
         * @param  {Event} event A keypress event
         */
        onKeyPress(event) {
            this.$emit('keypress', event)
        },

        /**
         * When a keyup occurs
         * @param  {Event} event A keyup event
         */
        onKeyUp(event) {
            this.$emit('keyup', event)
        },

        /**
         * Clear the input
         */
        clear() {
            this.gModal.autocompleteText = ''
        },

        /**
         * Focus the input
         */
        focus() {
            this.$refs.autocomplete.focus()
        },

        /**
         * Blur the input
         */
        blur() {
            this.$refs.autocomplete.blur()
        },

        /**
         * Update the value of the input
         * @param  {String} value
         */
        update(value) {
            this.gModal.autocompleteText = value
        },

        /**
         * Format result from Geo google APIs
         * @param place
         * @returns {{formatted output}}
         */
        formatResult(place) {
            const returnData = {}
            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0]

                if (ADDRESS_COMPONENTS[addressType]) {
                    returnData[addressType] =
                        place.address_components[i][
                            ADDRESS_COMPONENTS[addressType]
                        ]
                }
            }
            for (let i = 0; i < ADDRESS_ADICIONALS.length; i++) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        place,
                        ADDRESS_ADICIONALS[i]
                    )
                ) {
                    const attr = ADDRESS_ADICIONALS[i]
                    returnData[ADDRESS_ADICIONALS[i]] = place[attr]
                }
            }
            returnData.latitude = place.geometry.location.lat()
            returnData.longitude = place.geometry.location.lng()

            return returnData
        },
        // as supplied by the browser's 'navigator.geolocation' object.
        biasAutocompleteLocation() {
            if (this.enableGeolocation) {
                this.updateGeolocation((geolocation, position) => {
                    const circle = new this.google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy,
                    })
                    this.autocomplete.setBounds(circle.getBounds())
                })
            }
        },

        /**
         * Extract configured types out of raw result as
         * Geocode API does not allow to do it
         * @param results
         * @returns {GeocoderResult}
         * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
         */
        filterGeocodeResultTypes(results) {
            if (!results || !this.types || this.types.length === 0) return results
            const output = []
            let types = this.types
            if (types.includes('(cities)')) types = types.concat(CITIES_TYPE)
            if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE)

            for (const r of results) {
                for (const t of r.types) {
                    if (types.includes(t)) {
                        output.push(r)
                        break
                    }
                }
            }
            return output
        },
    },
}
</script>
<template>
    <b-row>
        <b-col sm="12" :md="displayMap ? '8' : '12'">
            <b-form-row>
                <b-col md="6">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :for="id"
                        :label="i18nAddressLabel"
                        :label-for="id"
                        :invalid-feedback="i18nInvalidAddress"
                    >
                        <b-form-input
                            :id="id"
                            ref="autocomplete"
                            v-model="gModal.autocompleteText"
                            type="text"
                            class="input-pdv-blue m-0"
                            placeholder="Informe o endereço"
                            :state="validateField('city', 'gModal')"
                            autocomplete="off"
                            :disabled="disabled"
                            @change="onChange"
                            @keypress="onKeyPress"
                            @keyup="onKeyUp"
                        />
                        <input
                            type="hidden"
                            name="placeId"
                            :value="gModal.placeId"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group
                        class="label-pdv"
                        :label="i18nStreetNumberLabel"
                        label-for="autocomplete_number"
                        :invalid-feedback="i18nChainInvalidNumber"
                    >
                        <b-form-input
                            id="autocomplete_number"
                            v-model="gModal.number"
                            name="number"
                            class="input-pdv-blue"
                            type="text"
                            autocomplete="off"
                            :disabled="disabled"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                        class="label-pdv"
                        :label="i18nComplementLabel"
                        label-for="autocomplete_complement"
                        :invalid-feedback="i18nInvalidComplementDistrictLabel"
                    >
                        <b-form-input
                            id="autocomplete_complement"
                            v-model="gModal.complement"
                            name="complement"
                            type="text"
                            class="input-pdv-blue"
                            autocomplete="off"
                            :disabled="disabled"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :label="i18nDistrictLabel"
                        label-for="autocomplete_district"
                        :invalid-feedback="i18nInvalidDistrictLabel"
                    >
                        <b-form-input
                            id="autocomplete_district"
                            v-model="$v.gModal.district.$model"
                            name="district"
                            class="input-pdv-blue"
                            type="text"
                            autocomplete="off"
                            :state="validateField('district', 'gModal')"
                            :disabled="disabled"
                            @input="clearResponseError('district', 'gModal')"
                            @blur="$v.gModal.district.$touch;"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group
                        class="label-pdv"
                        :label="i18nPostalCodeLabel"
                        label-for="autocomplete_postalCode"
                        :invalid-feedback="i18nInvalidPostalCode"
                    >
                        <b-form-input
                            id="autocomplete_postalCode"
                            v-model="gModal.postalCode"
                            class="input-pdv-blue"
                            name="postalCode"
                            type="text"
                            autocomplete="off"
                            :disabled="disabled"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :label="i18nState"
                        label-for="statesSelect"
                        :invalid-feedback="i18nInvalidStateLabel"
                    >
                        <translatedMultiselect
                            v-model="selectedState"
                            :options="statesWithCities"
                            :track-by="'id'"
                            :label="'label'"
                            :searchable="true"
                            disabled
                            :select-class="validationClass($v.gModal.state)"
                            @input="$v.gModal.state.$touch"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :label="i18nCity"
                        label-for="citySelect"
                        :invalid-feedback="i18nInvalidCityLabel"
                    >
                        <translatedMultiselect
                            v-model="selectedCity"
                            :options="citiesByState"
                            :track-by="'id'"
                            :label="'label'"
                            :searchable="true"
                            disabled
                            :select-class="validationClass($v.gModal.city)"
                            @input="$v.gModal.city.$touch"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :label="i18nLatitudeLabel"
                        label-for="autocomplete_latitude"
                        :invalid-feedback="i18nChainInvalidLatitude"
                    >
                        <b-form-input
                            id="autocomplete_latitude"
                            v-model="$v.gModal.latitude.$model"
                            name="latitude"
                            class="input-pdv-blue"
                            type="text"
                            autocomplete="off"
                            :state="validateField('latitude', 'gModal')"
                            disabled
                            @input="clearResponseError('latitude', 'gModal')"
                            @blur="$v.gModal.latitude.$touch;"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                        :class="required ? 'required label-pdv' : 'label-pdv'"
                        :label="i18nLongitudeLabel"
                        label-for="autocomplete_longitude"
                        :invalid-feedback="i18nChainInvalidLongitude"
                    >
                        <b-form-input
                            id="autocomplete_longitude"
                            v-model="$v.gModal.longitude.$model"
                            name="longitude"
                            class="input-pdv-blue"
                            type="text"
                            autocomplete="off"
                            :state="validateField('longitude', 'gModal')"
                            disabled
                            @input="clearResponseError('longitude', 'gModal')"
                            @blur="$v.gModal.longitude.$touch;"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-col>
        <b-col v-show="displayMap" sm="12" md="4">
            <gmap-map
                ref="gmap"
                :center="coordinatesMap"
                :zoom="16"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                    maxZoom: 18,
                }"
                style="width: 100%; height: 100%"
            >
                <gmap-marker
                    :position="coordinatesMap"
                    :icon="pinMarker"
                />
            </gmap-map>
        </b-col>
    </b-row>
</template>
<style>
.pac-container.pac-logo {
    z-index: 9999 !important;
}
</style>
